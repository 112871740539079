import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';

function IntercomChat() {
    Intercom({
        app_id: 'c5so7dic',
    });

    return <div></div>;
}

export { IntercomChat }