export const main = '#0C2340';
export const secondary = '#62400B';
export const accent = '#254d78'

export const teamColors = {
    'ATL': '#E03A3E',
    'BKN': '#000000',
    'BOS': '#007A33',
    'CHA': '#1D1160',
    'CHI': '#CE1141',
    'CLE': '#860038',
    'DAL': '#00538C',
    'DEN': '#0E2240',
    'DET': '#C8102E',
    'GSW': '#1D428A',
    'HOU': '#CE1141',
    'IND': '#002D62',
    'LAC': '#C8102E',
    'LAL': '#552583',
    'MEM': '#5D76A9',
    'MIA': '#98002E',
    'MIL': '#00471B',
    'MIN': '#0C2340',
    'NOP': '#0C2340',
    'NYK': '#006BB6',
    'OKC': '#007AC1',
    'ORL': '#0077C0',
    'PHI': '#006BB6',
    'PHX': '#1D1160',
    'POR': '#E03A3E',
    'SAC': '#5A2D81',
    'SAS': '#a4adb3',
    'TOR': '#CE1141',
    'UTA': '#002B5C',
    'WAS': '#002B5C',
}

export const mlbTeamColors = {
    'ARI': '#A71930',
    'ATL': '#CE1141',
    'BAL': '#DF4601',
    'BOS': '#BD3039',
    'CHC': '#0E3386',
    'CIN': '#C6011F',
    'CLE': '#00385D',
    'COL': '#33006F',
    'CWS': '#27251F',
    'DET': '#0C2340',
    'HOU': '#002D62',
    'KC': '#004687',
    'LAA': '#003263',
    'LAD': '#005A9C',
    'MIA': '#00A3E0',
    'MIL': '#12284B',
    'MIN': '#002B5C',
    'NYM': '#002D72',
    'NYY': '#003087',
    'OAK': '#003831',
    'PHI': '#E81828',
    'PIT': '#27251F',
    'SD': '#2F241D',
    'SEA': '#0C2C56',
    'SF': '#FD5A1E',
    'STL': '#C41E3A',
    'TB': '#092C5C',
    'TEX': '#003278',
    'TOR': '#134A8E',
    'WSH': '#AB0003',
}

export const nflTeamColors = {
    'ARI': '#97233F',
    'ATL': '#A71930',
    'BAL': '#241773',
    'BUF': '#00338D',
    'CAR': '#0085CA',
    'CHI': '#0B162A',
    'CIN': '#FB4F14',
    'CLE': '#311D00',
    'DAL': '#041E42',
    'DEN': '#FB4F14',
    'DET': '#0076B6',
    'GB': '#203731',
    'HOU': '#03202F',
    'IND': '#002C5F',
    'JAX': '#006778',
    'KC': '#E31837',
    'LV': '#000000',
    'LAC': '#002A5E',
    'LAR': '#003594',
    'MIA': '#008E97',
    'MIN': '#4F2683',
    'NE': '#002244',
    'NO': '#D3BC8D',
    'NYG': '#0B2265',
    'NYJ': '#125740',
    'PHI': '#004C54',
    'PIT': '#FFB612',
    'SEA': '#002244',
    'SF': '#AA0000',
    'TB': '#D50A0A',
    'TEN': '#0C2340',
    'WAS': '#773141',
}




